/**
 * Formatter object containing utility functions for formatting numbers and dates.
 */
export const formatter: {
  integer(x: number): string;

  getTime(value: string | number | Date): string;

  getDateTime(value: string | number | Date): string;
} = {
  integer: (x: number): string => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "0";
  },

  getTime: (value: string | number | Date): string => {
    const dateTime: Date = new Date(new Date(value).toISOString());

    return value
      ? dateTime

          .toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          })
          .replace(":", "h")
      : "";
  },

  getDateTime: (value: string | number | Date): string => {
    const dateTime: Date = new Date(value);
    return value
      ? dateTime.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }) +
          " à " +
          dateTime.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          })
      : "";
  },
};
