import Image from "next/image";
import { useSession } from "next-auth/react";
import React, { HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { refreshToken } from "@/app/api/dynabuy-back/api-auth";
import { deleteLogo, updateLogo } from "@/app/api/dynabuy-back/api-organization";
import { deleteAvatar, getMyProfile, updateAvatar } from "@/app/api/dynabuy-back/api-profile";
import Flash from "@/components/flash";
import { flashStates } from "@/TS/Interfaces/global_interfaces";
import { handleErrors } from "@/utils/formFunc/form-func";
function FileInput({
  type = "text",
  name,
  firstName,
  lastName,
  required = false,
  imageUrl,
  valueString,
  isAvatar,
  label,
  value,
  ...rest
}: {
  type: HTMLInputTypeAttribute;
  name: string;
  firstName?: string;
  lastName?: string;
  required?: boolean;
  imageUrl?: string;
  valueString?: string;
  isAvatar?: boolean;
  label?: string;
  value?: any;
}) {
  const [val, setVal] = useState(value);
  const {
    update
  } = useSession();
  const inputRef = useRef(null);
  const [url, setUrl] = useState(imageUrl);
  const [flash, setFlash] = useState<flashStates>({
    type: "",
    message: ""
  });
  useEffect(() => {
    setVal(value);
  }, [value]);
  const addLocalFlash = (type: string, message: string) => {
    setFlash({
      type: type,
      message: message
    });
    setTimeout(() => {
      setFlash({
        type: "",
        message: ""
      });
    }, 5000);
  };
  const handleFile = async (file, callback: Function) => {
    // 4194304 octets = 4MB
    if (file.size >= 4194304) {
      setFlash({
        type: "primary",
        message: "Votre fichier ne dois pas dépasser 4MB"
      });
      setTimeout(() => {
        setFlash({
          type: "",
          message: ""
        });
      }, 5000);
      return;
    }
    inputRef.current.value = null;
    if (url == "") {
      const newUrl = URL.createObjectURL(file);
      setUrl(newUrl);
    }
    if (file) {
      const newUrl = URL.createObjectURL(file);
      if (url !== newUrl) {
        setUrl(newUrl);
      }
    }
    const formData = new FormData();
    formData.append("file", file);
    const result = await callback(formData);
    if (result.error) {
      if (result.violations) {
        const violations = handleErrors(result.violations, "file");
        addLocalFlash("danger", violations.message);
      } else {
        addLocalFlash("danger", result.message);
      }
      setUrl(imageUrl);
      return;
    }
    const myProfile = await getMyProfile();
    update({
      image: myProfile.avatar?.urls?.avatar_md
    });
  };
  const deleteFile = async (callback: Function) => {
    setUrl("");
    const result = await callback();
    if (result.error) {
      addLocalFlash("danger", result.message);
      setUrl(imageUrl);
    }
  };
  return <div className={"w-full relative"} data-sentry-component="FileInput" data-sentry-source-file="file-input.tsx">
      {flash.message && <Flash type={flash.type} className={"grow w-auto"}>
          {flash.message}
        </Flash>}
      {isAvatar ? <div className="flex flex-col md:justify-start justify-center md:flex-row gap-4 items-center">
          {url ? <Image width={65} height={65} src={url} alt={firstName + " " + lastName} /> : <p className={"flex items-center  min-w-[42px] min-h-[42px] md:min-w-[52px] md:min-h-[52px] max-w-[42px] max-h-[42px] md:max-w-[52px] md:max-h-[52px] justify-center rounded-full bg-secondary  text-white text-xl md:text-2xl font-thin "} {...rest}>
              {firstName[0] + lastName[0]}
            </p>}

          <label className={"ring-2 text-nowrap ring-primary w-full text-center md:w-fit cursor-pointer h-fit p-4 py-3 font-bold hover:bg-primary hover:text-white rounded-full text-primary"} htmlFor={type}>
            Importer ma photo
          </label>
          <label className={twMerge("ring-2 ring-secondary cursor-pointer text-center h-fit p-4 py-3 font-bold hover:bg-secondary hover:text-white rounded-full text-secondary", !url && "opacity-35 bg-secondary text-white cursor-not-allowed")} onClick={() => {
        if (url) deleteFile(deleteAvatar);
      }}>
            Supprimer
          </label>
        </div> : <div className="flex flex-col gap-4 ">
          <label htmlFor={label}>
            <div className="bg-light-grey cursor-pointer flex p-4 rounded-lg items-center justify-center w-full">
              {url ? <Image width={85} height={85} className={"rounded-lg"} src={url} alt={"Logo"} /> : <p className={"h-28 flex text-lg items-center"}>Mon logo</p>}
            </div>
          </label>
          <div className="flex md:justify-center flex-col md:flex-row  gap-4">
            <label className={"ring-2 text-nowrap ring-primary text-center cursor-pointer h-fit p-4 py-3 font-bold hover:bg-primary hover:text-white rounded-full text-primary"} htmlFor={label}>
              Importer mon logo
            </label>
            <label className={twMerge("ring-2 ring-secondary cursor-pointer text-center h-fit p-4 py-3 font-bold hover:bg-secondary hover:text-white rounded-full text-secondary", !url && "opacity-35 bg-secondary text-white cursor-not-allowed")} onClick={() => {
          deleteFile(deleteLogo);
        }}>
              Supprimer
            </label>
          </div>
        </div>}
      <div className="hidden border-[1px] items-center h-[48px] rounded-md border-dark-grey justify-center relative">
        <input onChange={e => handleFile(e.target.files[0], isAvatar ? updateAvatar : updateLogo)} autoComplete={"on"} ref={inputRef} id={isAvatar ? type : label} name={name} className={"w-full hidden autofill:bg-transparent!important placeholder:italic required:text-primary  rounded-md  pl-4 pr-1"} type={type} value={val} required={required} {...rest} />
      </div>
    </div>;
}
export default FileInput;